import clsx, { type ClassValue } from 'clsx'
import './CartPopOverSummary.scss'
import { type FC, useEffect, useMemo, useState } from 'react'
import type { Cart, CartItem } from '@/types/shopify'
import { getCartItemCount, getItemsWithRequiredLogin } from '@/scripts/cart'
import { chord } from '@/scripts/tracking/chord'

interface CartPopOverSummaryProps {
  cart: Cart
  classes: ClassValue[]
}

const CartPopOverSummary: FC<CartPopOverSummaryProps> = ({ cart, classes }) => {
  const [itemsCount, setItemsCount] = useState(0)
  const [subtotalAmount, setSubtotalAmount] = useState(0)
  const [checkoutUrl, setCheckoutUrl] = useState('#')

  const displayText = useMemo(() => {
    const displayTextInterim = {
      itemsCount: '',
      subtotalAmount: '',
      checkoutBtn: '',
    }
    displayTextInterim.itemsCount = `${itemsCount} items`
    displayTextInterim.subtotalAmount = `$${subtotalAmount}`
    displayTextInterim.checkoutBtn = 'proceed to checkout'

    return displayTextInterim
  }, [itemsCount, subtotalAmount])

  async function getItemsRequiredLogin(lines: CartItem[]) {
    const skuList = lines.map((line) => line.merchandise.sku)
    const idList = lines.map((line) => line.merchandise.id)
    const isRequiredLogin = await getItemsWithRequiredLogin(skuList, idList)
    if (isRequiredLogin) setCheckoutUrl('/account/gateway')
  }

  function updateState(cartData: Cart) {
    const {
      lines,
      cost: {
        subtotalAmount: { amount: subtotalAmount },
      },
      checkoutUrl,
    } = cartData
    getItemsRequiredLogin(lines)
    setItemsCount(getCartItemCount(cartData))
    setSubtotalAmount(Number(subtotalAmount))
    setCheckoutUrl(checkoutUrl)
  }

  useEffect(() => {
    updateState(cart)
  }, [cart])

  const checkoutButtonClass = [
    'button',
    'button--tertiary',
    'cart-pop-over__summary__checkout-btn',
  ]

  const trackPage = () => {
    chord?.trackCheckoutStarted({
      checkout: cart,
    })
  }

  return (
    <div className={clsx(classes)}>
      <div className="cart-pop-over__summary__content">
        <p className="type-label">Cart Subtotal</p>
        <p className="type-body">{displayText.subtotalAmount}</p>
      </div>
      <div className="cart-pop-over__summary__actions">
        <a className={clsx(checkoutButtonClass)} href="/cart">
          View Cart
        </a>
        <a
          className={clsx(checkoutButtonClass)}
          href={checkoutUrl}
          onClick={trackPage}
        >
          Checkout
        </a>
      </div>
    </div>
  )
}

export default CartPopOverSummary
