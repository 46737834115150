import { getCartItemCount } from '@/scripts/cart'
import { $cart } from '@/stores/cart/stores'
import type { Cart } from '@/types/shopify'
import { useStore } from '@nanostores/react'
import * as Popover from '@radix-ui/react-popover'
import { useCallback, useEffect, useMemo, useState } from 'react'
import './Cart.scss'
import LineItemList from '../CartPopover/LineItemList/LineItemList'
import clsx from 'clsx'

const CartIconButton = () => {
  const [headerCartCount, setHeaderCartCount] = useState(0)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const cart = useStore($cart)

  const updateCartCount = (detail: Cart) => {
    setHeaderCartCount(getCartItemCount(detail))
  }

  const anchorClasses = useMemo(
    () => [
      'header-cart',
      'type-label',
      { 'header-cart__active': isPopoverOpen },
    ],
    [isPopoverOpen],
  )

  const countClasses = useMemo(
    () => [
      'header-cart__count',
      { 'header-cart__count__active': isPopoverOpen },
    ],
    [isPopoverOpen],
  )

  useEffect(() => {
    if (cart) {
      updateCartCount(cart)
    }
  }, [cart])

  const getVh = useCallback(() => {
    return window?.innerHeight || 0
  }, [])
  const getVw = useCallback(() => {
    return window?.innerWidth || 0
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setVw(getVw())
    }
    window?.addEventListener('resize', handleResize)
    return () => {
      window?.removeEventListener('resize', handleResize)
    }
  }, [getVh, getVw])

  const [clientWidth, setVw] = useState<number>(getVw())

  const isScreenLarge = useMemo(() => clientWidth >= 768, [clientWidth])

  return (
    <Popover.Root open={isPopoverOpen}>
      <Popover.Trigger
        asChild
        onMouseEnter={() => {
          if (isScreenLarge) {
            cart?.lines.length && setIsPopoverOpen(true)
          }
        }}
      >
        <a
          className={clsx(anchorClasses)}
          href="/cart"
          aria-label="go to the cart page"
        >
          {!isPopoverOpen ? (
            <svg
              className="header-cart__icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 27"
              fill="none"
            >
              <path
                fill="#000"
                fillRule="evenodd"
                d="M6.5 4.379C6.5 2.527 8.052 1 10 1s3.5 1.527 3.5 3.379V5.5h-7V4.379ZM5.5 6.5v2h1v-2h7v2h1v-2h4l-.003 19H1.5v-19h4Zm0-1V4.379C5.5 1.946 7.53 0 10 0s4.5 1.946 4.5 4.379V5.5h5V6l-.003 20v.5H.5v-21h5Z"
                clipRule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg
              width="20"
              height="27"
              viewBox="0 0 20 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.49997 4.37879C6.49997 2.52713 8.05236 1 9.99997 1C11.9476 1 13.5 2.52713 13.5 4.37879V5.5H6.49997V4.37879ZM5.49997 5.5V4.37879C5.49997 1.94606 7.5293 0 9.99997 0C12.4706 0 14.5 1.94606 14.5 4.37879V5.5H19H19.5L19.5 6.00007L19.4971 26.0001L19.497 26.5H18.9971H0.999969H0.499969V26V6V5.5H0.999969H5.49997Z"
                fill="black"
              />
            </svg>
          )}

          <span className={clsx(countClasses)}>
            {headerCartCount ? headerCartCount : ''}
          </span>
        </a>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="PopoverContent"
          align="end"
          onCloseAutoFocus={() => {
            setIsPopoverOpen(false)
          }}
          onInteractOutside={() => {
            setIsPopoverOpen(false)
          }}
          onMouseLeave={() => {
            setIsPopoverOpen(false)
          }}
        >
          {cart && <LineItemList cart={cart} />}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default CartIconButton
